import React from "react"
import usePolls from "../../Hooks/usePolls";

const AdminApp = () => {
    const {loading, statsLoading, polls, stats, getStats} = usePolls()

    return (
        <>
            {!loading &&
                <div className="stat-container"> {/* @ts-ignore */}
                    <select onClick={e => getStats(e.target.value)}>
                        {polls.map((poll: any) => (
                            <option value={poll.id} key={poll.id}>{poll.params.title}</option>
                        ))}
                    </select>
                    {!statsLoading && (stats.hasOwnProperty('stats')) &&
                    <div className="stats">
                        <h4 style={{margin: '0'}}>{stats.question}</h4>
                        <h6>Totaal: {stats.count} stemmen</h6>
                        {Object.keys(stats.stats).map((stat: string, key: number) => (
                            <span
                                className={"stat"}
                                key={key}
                            >
                            <span className="name">{stats.stats[stat].name}</span>
                            <span className="bar">
                                <span className="bar-holder">
                                    <span className="line" style={{width: stats.stats[stat].percentage + "%"}} />
                                </span>
                                <span className="percent">{stats.stats[stat].percentage}%</span>
                            </span>
                        </span>
                        ))}
                    </div>
                    }
                </div>
            }
        </>
    )
};

export default AdminApp