import React, {useState} from "react"
import usePoll from "../../Hooks/usePoll"
import '../../Providers/Lang'
import {useTranslation} from "react-i18next";

const App = ({ id } : {id: number}) => {
    const {loading, answers, answerQuestion, statsLoading, stats, hasAnswered} = usePoll(id)
    const [selected, setSelected] = useState<string>(null)
    const {t} = useTranslation()

    return (
        <div className={statsLoading ? "loading" : ""}>
            {!loading && !hasAnswered &&
                <>
                    <div className={"answers"}>
                        {Object.keys(answers).map((answer: string, key: number) => (
                            <span
                                className={"answer" + (answers[answer].answer === selected ? " selected" : "")}
                                key={key}
                                onClick={() => setSelected(answers[answer].answer)}
                            >
                            {answers[answer].answer}
                        </span>
                        ))}
                    </div>
                    {!statsLoading &&
                    <button
                        className={"btn btn-poll" + (selected === null ? " disabled" : "")}
                        onClick={() => {
                            if (selected !== null) {
                                answerQuestion(selected)
                            }
                        }}
                    >
                        {t("vote")}
                    </button>
                    }
                </>
            }
            {statsLoading && !hasAnswered && <button className={"btn btn-poll disabled"}>{t("loading")}</button>}
            {!loading && !statsLoading && hasAnswered &&
            <div className="stats">
                {Object.keys(stats.stats).map((stat: string, key: number) => (
                    <span
                        className={"stat"}
                        key={key}
                    >
                            <span className="name">{stats.stats[stat].name}</span>
                            <span className="bar">
                                <span className="bar-holder">
                                    <span className="line" style={{width: stats.stats[stat].percentage + "%"}} />
                                </span>
                                <span className="percent">{stats.stats[stat].percentage}%</span>
                            </span>
                        </span>
                ))}
            </div>
            }
        </div>
    )
}

export default App