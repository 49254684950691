"use strict"

import React from "react";
import ReactDOM from "react-dom";
import App from "./Containers/App/App";
import AdminApp from "./Containers/Admin/App";

if (module.hot) {
    console.log('poll refresh ok');
    module.hot.accept([], () => {window.location.reload()});
}

const start = () => {
    const pollElement = document.getElementById('poll-container')
    const pollId = pollElement ? pollElement.attributes.getNamedItem('data-poll') : null
    if (pollElement && pollId && pollId.value) {
        ReactDOM.render(<App id={Number(pollId.value)} />, pollElement)
    }

    const adminPollElement = document.getElementById('admin-poll-container')
    if (adminPollElement) {
        ReactDOM.render(<AdminApp />, adminPollElement)
    }
}
document.addEventListener('DOMContentLoaded', start)