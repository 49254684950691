import i18n from "i18next"
import {initReactI18next} from "react-i18next" // @ts-ignore
import nl_NL from "../Lang/nl/common.json"

// the translations
const resources = {
    nl: {
        common: nl_NL,
    },
}

const getLang = (): string => {
    return 'nl'
}


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        defaultNS: 'common',
        lng: getLang(),

        //keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n

