"use strict"

import {useState} from "react";

const usePolls = (): any => {
    const axios = require('axios')
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(true)
    const [polls, setPolls] = useState({})
    const [statsLoading, setStatsLoading] = useState(false)
    const [stats, setStats] = useState({})
    const [pollId, setPollId] = useState(localStorage.getItem('last_admin_poll') !== null ? Number(localStorage.getItem('last_admin_poll')) : null)

    const getStats = (id: number, force: boolean = false) => {
        if ((id === pollId) && !force) {
            return;
        }
        setPollId(id)
        localStorage.setItem('poll26_last_admin_poll', String(id))
        setStatsLoading(true)
        axios.post('/?option=com_ajax&module=poll26&method=getStats&format=json',
            JSON.stringify({
                id: id,
            })
        ).then((response: any) => {
            setStats(response.data)
            setStatsLoading(false)
        })
    }

    if (!initialized) {
        setInitialized(true)
        axios.get('/?option=com_ajax&module=poll26&method=getAllPolls&format=json'
        ).then((response: any) => {
            setPolls(response.data)
            setLoading(false)
        })

        if (pollId !== null) {
            getStats(pollId, true)
        }
    }

    return {loading, statsLoading, polls, stats, getStats, pollId, setPollId}
}

export default usePolls