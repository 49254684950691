"use strict"

import {useState} from "react"
import {v4 as uid} from "uuid"

const usePoll = (id: number): {loading: boolean, answers: any, params: object, answerQuestion: Function, statsLoading: boolean, stats: any, hasAnswered: boolean} => {
    const axios = require('axios')
    const [hasAnswered, setHasAnswered] = useState<boolean>(localStorage.getItem('poll26' + id) !== null)
    const [initialized, setInitialized] = useState(false)
    const [loading, setLoading] = useState(true)
    const [statsLoading, setStatsLoading] = useState(false)
    const [answers, setAnswers] = useState({})
    const [params, setParams] = useState({})
    const [stats, setStats] = useState({})
    if (localStorage.getItem('poll26_user_id') === null) {
        localStorage.setItem('poll26_user_id', uid())
    }
    const pollUserId = localStorage.getItem('poll26_user_id');

    if (!initialized && !hasAnswered) {
        setInitialized(true)
        axios.post('/?option=com_ajax&module=poll26&method=getPossibleAnswers&format=json',
            JSON.stringify({
                id: id,
            })
        ).then((response: any) => {
            setAnswers(response.data.answers)
            setParams(response.data)
            setLoading(false)
        })
    }

    if (!initialized && hasAnswered) {
        setInitialized(true)
        setStatsLoading(true)
        axios.post('/?option=com_ajax&module=poll26&method=getStats&format=json',
            JSON.stringify({
                id: id,
            })
        ).then((response: any) => {
            setStats(response.data)
            setStatsLoading(false)
            setLoading(false)
        })
    }

    const answerQuestion = (answer: string) => {
        setStatsLoading(true)
        axios.post('/?option=com_ajax&module=poll26&method=setAnswer&format=json',
            JSON.stringify({
                id: id,
                answer: answer,
                user_identifier: pollUserId,
            })
        ).then((response: any) => {
            setStats(response.data)
            setStatsLoading(false)
            setHasAnswered(true)
            localStorage.setItem('poll26' + id, '1')
        })
    }

    return {loading, answers, params, answerQuestion, statsLoading, stats, hasAnswered}
}

export default usePoll